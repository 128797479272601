<template>
  <div class="Part">
    <!-- 条件搜素开始 -->
    <div class="input">
      <!-- <el-row>
				<el-button type="primary" size="mini">demo</el-button>	
      </el-row> -->
			<el-row style="display: flex">
        <el-form style="display: flex;">
					<el-form-item label="门店" label-width="60px">
						<el-input size="mini" @change="conditionQuery('1')" class="screenInput" v-model="records.params.hdAddCommunity" clearable></el-input>
					</el-form-item>
					<el-form-item label="楼栋" label-width="60px">
						<el-input size="mini" @change="conditionQuery('2')" class="screenInput" v-model="records.params.hsAddBuilding" clearable></el-input>
					</el-form-item>
					<el-form-item label="门牌号" label-width="60px" >
						<el-input size="mini" @change="conditionQuery('3')" class="screenInput" v-model="records.params.hsAddDoorplateno" clearable></el-input>
					</el-form-item>
        </el-form>
        <el-popover placement="bottom" width="156" style="margin-left: 10px;">
          <el-button slot="reference" size="mini">
            <span>排序方式</span>
            <i class="el-icon-sort el-icon--right"></i>
          </el-button>
          <el-button-group>
            <el-button :type="sortBtns.sortGp.descBtn" @click="sortPopoverBtnGp('descBtn','降序')" size="mini" style="width: 78px;">降序<i class="el-icon-sort-down el-icon--right"></i></el-button>
            <el-button :type="sortBtns.sortGp.ascBtn" @click="sortPopoverBtnGp('ascBtn','升序')" size="mini" style="width: 78px;">升序<i class="el-icon-sort-up el-icon--right"></i></el-button>
          </el-button-group>
          <div>  
            <p><el-button :type="sortBtns.btns.lp" class="btns"  @click="sortord('lp','本次空置天数')" size="mini" style="width: 100%;">本次空置天数</el-button></p>
            <p><el-button :type="sortBtns.btns.ld" class="btns"  @click="sortord('ld','累计空置天数')" size="mini" style="width: 100%;">累计空置天数</el-button></p>
            <p><el-button :type="sortBtns.btns.mph" class="btns"  @click="sortord('mph','累计运营天数')" size="mini" style="width: 100%;">累计运营天数</el-button></p>
            <p><el-button :type="sortBtns.btns.sblx" class="btns"  @click="sortord('sblx','累计空置率')" size="mini" style="width: 100%;">累计空置率</el-button></p>
          </div>  
        </el-popover>
			</el-row>
    </div>
    <!-- 条件搜索结束 -->

    <!-- 数据表开始 -->
    <el-row style="height:100%;">
      <el-col :span="3" class="commName">
        <el-table style="width: 100%" ref="singleTable" @row-click="commNameClick" :data="records.villageName" highlight-current-row :height="tableHeight">
          <el-table-column prop="hdCommunity" label="小区名称"></el-table-column>
        </el-table>
      </el-col>
      <el-col :span="3" class="custom" style=" height: 100%;color: #909399;text-align: center;">
        <p>楼盘概况</p>
        <p>运营房间数:{{records.oddRecords.hdAveoperatiomDays}}</p>
        <p>空置房间数:{{records.oddRecords.hdVacantRooms}}</p>
        <p>平均空置房间数:{{records.oddRecords.hdAvevacancyDays}}</p>
        <p>平均运营天数:{{records.oddRecords.hdAveoperatiomDays}}</p>
        <p>累计空置率:{{records.oddRecords.hdVacancyRate | calculatePercentage}}</p>
        <p>数据日期:<br/>{{records.oddRecords.hdUpdateTime}}</p>
      </el-col>
      <el-col :span="18" style="height: 100%;" class="commName">
        <el-table style="width: 100%;" :data="rvparams.roomList" :height="tableHeight" >
          <el-table-column align="center"  label="物业地址" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span>{{scope.row.hsAddDistrict}}-{{scope.row.hsAddCommunity}}-{{scope.row.hsAddDoorplateno}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="hsVacancyDay" label="本次空置天数" ></el-table-column>
          <el-table-column align="center" prop="hsTotalVacancyDay" label="累计空置天数"></el-table-column>
          <el-table-column align="center" prop="hsVacancyDay" label="累计运营天数"></el-table-column>
          <el-table-column align="center" prop="hsCumulativeVacancyRate" label="累计空置率">
            <template slot-scope="scope">
              <span>{{scope.row.hsCumulativeVacancyRate | calculatePercentage}}</span>
            </template>          
          </el-table-column>
          <el-table-column align="center" label="同小区平均空置率">
            <template >
              <span>{{records.oddRecords.hdVacancyRate | calculatePercentage}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="hsUpdateTime" label="数据日期" :show-overflow-tooltip="true"></el-table-column>
        </el-table>  
        <!-- <el-pagination background align="center"></el-pagination> -->
        <!-- <el-pagination
          align="center"
          @size-change="rightSiziPigingReq"
          @current-change="rightCurrentPigingReq"
          :page-sizes="[5, 10, 20, 40]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="rvparams.result.total">
        </el-pagination> -->
      </el-col>
    </el-row>
    <!-- 数据表结束 -->

    <!-- 分页开始 -->
    <div class="block" >
      <el-row style="width: 100%;">
        <el-col :span="6">
          <el-pagination
            small
            @size-change="siziPigingReq"
            @current-change="currentPigingReq" 
            :page-sizes="[5, 10, 20, 40]"
            :page-size="5"
            layout="sizes, prev, pager, next"
            :total="records.pages">
          </el-pagination>
        </el-col>
        <el-col :span="18">
          <el-pagination
            style="margin-right: 25%;"
            align="center"
            @size-change="rightSiziPigingReq"
            @current-change="rightCurrentPigingReq"
            :page-sizes="[5, 10, 20, 40]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="rvparams.result.total">
          </el-pagination>
        </el-col>
      </el-row>
    </div>
    <!-- 分页结束 -->

    </div>
</template>
<script>
export default {
	
	data(){
		return {
      // 排序方式
      sortBtns:{
        btns:{
          lp: 'primary',
          ld: '',
          mph:'',
          sblx:'',
        },
        sortGp: {//正倒序
            ascBtn: '',
            descBtn: 'primary',
        },
      },
      refuse:true,
			tableHeight: window.innerHeight-200,
      currentRow: null,
      // 小区数据表
      records:{ 
        villageName:[],
        total:0,
        //请求参数 hotel/houseDict/conditionalScreening 
        params:{
          cursor:1, //当前页数
          limit:5,  //每页条数
          hdAddCommunity:'',  //楼盘/小区名称
          hsAddBuilding:'',  //楼盘/小区楼栋
          hsAddDoorplateno:''  //楼盘/小区房间门牌号
        },
        oddRecords:{}, //单条小区概况数据
        // 请求参数 /hotel/house4store/getRoomVacancy
      },
      //右侧数据表
      rvparams:{
        roomList:[],
        residentialAddress:'',
        result:{},
        params:{
          cursor:1,
          limit:5, 
          hsHouseDictId:'', //楼盘/小区id
          sortCriteria: '本次空置天数', //排序条件
          sortType:'降序', //升序降序
          hsAddCommunity:'', //楼盘/小区名称
          hsAddBuilding:'',//楼盘/小区楼栋
          hsAddDoorplateno:'',//楼盘/小区房间门牌号
        },
      }
		}
  },
  watch: {
    'records.villageName':function(n,o){
      this.rvparams.params.hsHouseDictId=n[0].hdId
      this.getRoomVacancy()
    }
  },
  filters:{
    calculatePercentage(val){
      return (val==1 ? '100%': `${(val*100).toFixed(3)}%`)
    }
  },
  methods: {
    commNameClick(row){
      console.log(row)
      this.records.oddRecords= row;
      this.rvparams.params.hsHouseDictId=row.hdId
      this.getRoomVacancy()
    },
    setCurrent(row) {
      console.log(row)
      this.$refs.singleTable.setCurrentRow(row);
    },
    getRealEstateSituation(){
      let params = this.records.params
      this.$axios({
        method: 'get',
        url: `/hotel/houseDict/conditionalScreening`,
        params
      }).then(res => {
        this.records.villageName=res.data.result.records
        this.records.oddRecords=res.data.result.records[0]
        this.records.pages=res.data.result.total
        if(this.refuse){
          this.rvparams.params.hsHouseDictId=this.records.villageName[0].hdId
          this.getRoomVacancy()
          this.refuse=false
        }
        this.$nextTick(()=>{
          this.setCurrent(this.records.villageName[0])
        })
      }).catch(err => {
        this.isError(err,this)
      })    
    },
    // 右侧数据表请求
    getRoomVacancy(){
      let params=this.rvparams.params
      this.$axios({
        method: 'get',
        url: `/hotel/house4store/getRoomVacancy`,
        params
      }).then(res => {
        this.rvparams.roomList=res.data.result.records
        this.rvparams.result=res.data.result
        console.log(this.rvparams.result)
      }).catch(err => {
        this.isError(err,this)
      }) 

    },
    siziPigingReq(val){
      console.log(val)
      this.records.params.limit=val
      this.records.params.cursor=1
      this.getRealEstateSituation()
    },
    rightSiziPigingReq(val){
      this.rvparams.params.limit=val
      this.getRoomVacancy()
    },
    currentPigingReq(val){
      this.records.params.cursor=val
      this.getRealEstateSituation()
    },
    rightCurrentPigingReq(val){
      this.rvparams.params.cursor=val
      this.getRoomVacancy()
    },
    conditionQuery(val){
      this.getRealEstateSituation()
    },
    sortord(key,value){//排序方式
      for(let i in this.sortBtns.btns){
        if(key==i){
          this.sortBtns.btns[i]='primary'
        }else{
          this.sortBtns.btns[i]=''
        }
      }
      this.rvparams.params.sortCriteria=value
      this.getRoomVacancy()
    },
    sortPopoverBtnGp(key,value){//正倒排序
      for (let i in this.sortBtns.sortGp) {
        if (i == key) {
          this.sortBtns.sortGp[i] = 'primary'
        }else{
          this.sortBtns.sortGp[i] = ''
        }
      }
      this.rvparams.params.sortType=value
      this.getRoomVacancy()
    },
  },
  mounted(){
    this.getRealEstateSituation()
  }
}
</script>
<style lang="scss">
  .screenInput{
    width: 125px;
  }
  .custom{
    border-left:1px solid #EBEEF5 ;
    border-right:2px solid #EBEEF5 ;
    p{
      margin: 0 0;
      border-bottom: 1px solid #EBEEF5;
      color: #606266;
      font-size: 14px;
      padding: 13.5px 0;
    }
    p:first-child{
      padding: 14px 0;
      font-weight: 900;
      color: #909399;
    }
  }
  .commName{
    height: 100%;
  }
  .block{
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 6px;
  }
</style>